/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import CategoryMenuSelector from './CategoryMenuSelector';

export default function CategoryMenu(props) {
  const {
    categoryType, activeCategory, listQuery
  } = props;

  const modalRef = useRef(null);
  const modalListRef = useRef(null);
  const categoryTabRef = useRef(null);
  const worksList = [
    'All Projects',
    'Progressive Research',
    'Architecture',
    'Urban Design',
    'Interior Design',
    'Product Design',
    'Exhibition',
    'Publication'
  ];
  const mediaList = [
    'All News',
    'Featured Publications',
    'Writings',
    'Studio News',
    'Online Videos',
    'Media Coverage'
  ];
  const worksListKeys = worksList.map((worksCategory) => worksCategory.replace(/\s/g, '').toLowerCase());
  const mediaListKeys = mediaList.map((mediaCategory) => mediaCategory.replace(/\s/g, '').toLowerCase());

  function getProjectCountByCategory(categoryName) {
    if (categoryType === 'Works') {
      switch (categoryName) {
        case 'All Projects':
          return listQuery.allSanityProject.nodes.length;
        default:
          return listQuery.allSanityProject.nodes.filter((node) => (
            node.category.some((catItem) => (catItem.title === categoryName)))).length;
      }
    }
    if (categoryType === 'Media') {
      switch (categoryName) {
        case 'Highlights':
          return listQuery.allSanityMedia.nodes.filter((node) => node.featured === 'y').length;
        case 'All News':
          return listQuery.allSanityMedia.nodes.length;
        case 'Featured Publications':
          return listQuery.allSanityMedia.nodes.filter(
            (node) => node.mediaCategory.some((category) => category.title === 'Featured Publications')
          ).length;
        case 'Writings':
          return listQuery.allSanityMedia.nodes.filter(
            (node) => node.mediaCategory.some((category) => category.title === 'Writings')
          ).length;
        case 'Studio News':
          return listQuery.allSanityMedia.nodes.filter(
            (node) => node.mediaCategory.some((category) => category.title === 'Studio News')
          ).length;
        case 'Online Videos':
          return listQuery.allSanityMedia.nodes.filter(
            (node) => node.mediaCategory.some((category) => category.title === 'Online Videos')
          ).length;
        case 'Media Coverage':
          return listQuery.allSanityMedia.nodes.filter(
            (node) => node.mediaCategory.some((category) => category.title === 'Media Coverage')
          ).length;
        default:
          return 'other news';
      }
    }
    throw new Error('unexpected categoryType in getProjectCountByCategory()');
  }
  function handleCategoryTabClick(e) {
    if (e.target === categoryTabRef.current || e.target.className === 'category-tab-p') {
      modalRef.current.className = 'category-modal';
    }
  }
  function handleModalEscapeClick(e) {
    if (e.target !== modalListRef.current) {
      modalRef.current.className = 'category-modal hide';
    }
  }

  return (
    <div className="category-group">
      <div className="category-modal hide" ref={modalRef} onClick={handleModalEscapeClick}>
        <div className="category-modal-menu" ref={modalListRef}>
          <p className="category-type">{categoryType}</p>
          <ul>
            {categoryType === 'Works'
              ? worksList.map((category, i) => (
                <CategoryMenuSelector
                  key={worksListKeys[i]}
                  categoryType={categoryType}
                  category={category}
                  activeCategory={activeCategory}
                  categoryCount={getProjectCountByCategory(category)}
                />
              )) : mediaList.map((category, i) => (
                <CategoryMenuSelector
                  key={mediaListKeys[i]}
                  categoryType={categoryType}
                  category={category}
                  activeCategory={activeCategory}
                  categoryCount={getProjectCountByCategory(category)}
                />
              ))}
          </ul>
        </div>
      </div>
      <div className="category-tab" ref={categoryTabRef} onClick={handleCategoryTabClick}>
        <p className="category-tab-p">Categories</p>
      </div>
      <div className="category-menu">
        <p className="category-type">{categoryType}</p>
        <ul>
          {categoryType === 'Works'
            ? worksList.map((category, i) => (
              <CategoryMenuSelector
                key={worksListKeys[i]}
                categoryType={categoryType}
                category={category}
                activeCategory={activeCategory}
                categoryCount={getProjectCountByCategory(category)}
              />
            )) : mediaList.map((category, i) => (
              <CategoryMenuSelector
                key={mediaListKeys[i]}
                categoryType={categoryType}
                category={category}
                activeCategory={activeCategory}
                categoryCount={getProjectCountByCategory(category)}
              />
            ))}
        </ul>
      </div>
    </div>
  );
}
